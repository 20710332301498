import React, { useState } from "react";
import logo from "./logo.svg";
import prof from "./assets/prof.png";
import video from "./assets/mccloskey.mp4";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import ConfettiExplosion from "@reonomy/react-confetti-explosion";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/mccloskey">
          <Mccloskey />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

function Home() {
  const [isExploding, setIsExploding] = useState(false);
  const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState(false);

  function explode() {
    console.log("explosion");
    setIsExploding(true);
    setButtonHasBeenClicked(true);
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={prof} className="prof-picture" />
        <div>
          {isExploding && <ConfettiExplosion />}
          {!buttonHasBeenClicked && (
            <div>
              <p>You found my website!</p>
              <button onClick={explode}>Claim your Prize</button>
            </div>
          )}
          {buttonHasBeenClicked && <p>Enough fun, back to work.</p>}
        </div>
      </header>
    </div>
  );
}

function Mccloskey() {
  return (
    <div className="App">
      <header className="App-header">
        <video width="640" height="480" controls>
          <source src={video} type="video/mp4" />
          Your browser does not support thi video.
        </video>
        <p>Round 1 McCloskey Submission Video</p>
        <a href="https://www.getclearspace.com" className="App-link">
          check out clearspace
        </a>
      </header>
    </div>
  );
}
